import React, { FunctionComponent } from 'react'
import { graphql, PageProps } from 'gatsby'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { css } from 'styled-components'

import Page from '~/components/Page'
import Link from '~/components/Link'
import IndexLayout, { IndexLayoutDataProps } from '../layouts/IndexLayout'

// locale is provided through PageProps
// @see: https://www.gatsbyjs.org/docs/gatsby-internals-terminology/#pagecontext
export const query = graphql`
	query($locale: String) {
		...ContentfulMetaData
		...FooterData
	}
`

type Props = {
	data: {}
} & IndexLayoutDataProps &
	PageProps

const NotFoundPage: FunctionComponent<Props> = ({ location, data, pageContext }) => (
	<IndexLayout data={data} location={location} pageContext={pageContext}>
		<Page>
			<Box
				display="flex"
				bgcolor="secondary.main"
				color="secondary.contrastText"
				alignItems="center"
				justifyContent="center"
				minHeight="500px"
			>
				<Container
					css={css`
						text-align: center;
					`}
				>
					<Typography variant="h1" color="inherit" gutterBottom>
						404: Page not found.
					</Typography>
					<Typography variant="body1">
						Sorry, that page can't be found.
						<br />
						<br />
						<Link to="/">Please go back</Link>
					</Typography>
				</Container>
			</Box>
		</Page>
	</IndexLayout>
)

export default NotFoundPage
